import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RepModel } from '@models';
import { CreateRepModel } from '../models/rep/create-rep.model';
import { UpdateRepModel } from '../models/rep/update-rep.model';

@Injectable({
    providedIn: 'root'
})
export class RepService
{
    private url = '/reps';

    constructor(
        private http: HttpClient,
    )
    {
    }

    getAll(): Promise<RepModel[]>
    {
        return this.http
            .get<RepModel[]>(this.url)
            .toPromise();
    }

    create(rep: CreateRepModel): Promise<void>
    {
        return this.http
            .post<void>(this.url, rep)
            .toPromise();
    }

    update(rep: UpdateRepModel): Promise<void>
    {
        return this.http
            .put<void>(this.url, rep)
            .toPromise();
    }
}
