import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { NbDialogModule, NbMenuModule, NbSidebarModule, NbToastrModule } from '@nebular/theme';

import { AppComponent } from './app.component';

import { CoreModule } from '@core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from '@theme/theme.module';
import { AgmCoreModule } from '@agm/core';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,

        NbSidebarModule.forRoot(),
        NbDialogModule.forRoot(),
        NbMenuModule.forRoot(),
        NbToastrModule.forRoot(),

        ThemeModule.forRoot(),

        CoreModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBEwKtDEOrBfNTskr6Y_rKLS1EFrwMSatA'
        })
    ],
    bootstrap: [AppComponent]
})
export class AppModule
{
}
