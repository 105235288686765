import { Component, OnInit } from '@angular/core';

import { PrimeNGConfig } from 'primeng/api';

import { AppMenuService } from '@services';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit
{
    /**
     * Static menu for Mobile screen sizes
     * Always true if isMobile method below can return true
     */
    staticMenuMobileActive: boolean;

    /**
     * Static menu for Desktop screen sizes
     * Always true if isDesktop method below can return true
     * Using to hide or show left menu by clicking on menu button at top bar left side
     */
    staticMenuDesktopInactive: boolean;

    /**
     * Click on User DropDown Menu (name, image)
     *
     * @private
     */
    private topBarUserMenuClick: boolean;
    topBarUserMenuActive: boolean;

    /**
     * Click on Left Menu area
     *
     * @private
     */
    private leftMenuClick: boolean;

    constructor(
        private menuService: AppMenuService,
        private primengConfig: PrimeNGConfig,
    )
    {
    }

    ngOnInit()
    {
        this.primengConfig.ripple = true;
    }

    onLayoutClick()
    {
        if (!this.topBarUserMenuClick)
        {
            this.topBarUserMenuActive = false;
        }

        if (!this.leftMenuClick)
        {
            if (this.staticMenuMobileActive)
            {
                this.hideOverlayMenu();
            }

            this.unblockBodyScroll();
        }

        this.topBarUserMenuClick = false;
        this.leftMenuClick = false;
    }

    onMenuButtonClick(event)
    {
        this.leftMenuClick = true;
        this.topBarUserMenuActive = false;

        if (this.isDesktop())
        {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        }
        else
        {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;

            if (this.staticMenuMobileActive)
            {
                this.blockBodyScroll();
            }
            else
            {
                this.unblockBodyScroll();
            }
        }

        event.preventDefault();
    }

    onLeftMenuAreaClick($event)
    {
        this.leftMenuClick = true;
        // this.resetMenu = false;
    }

    onTopBarUserMenuButtonClick(event)
    {
        this.topBarUserMenuClick = true;
        this.topBarUserMenuActive = !this.topBarUserMenuActive;
        this.hideOverlayMenu();

        event.preventDefault();
    }

    private hideOverlayMenu()
    {
        this.staticMenuMobileActive = false;
    }

    private isDesktop()
    {
        return window.innerWidth > 991;
    }

    private isMobile()
    {
        return window.innerWidth <= 991;
    }

    private blockBodyScroll(): void
    {
        if (document.body.classList)
        {
            document.body.classList.add('blocked-scroll');
        }
        else
        {
            document.body.className += ' blocked-scroll';
        }
    }

    private unblockBodyScroll(): void
    {
        if (document.body.classList)
        {
            document.body.classList.remove('blocked-scroll');
        }
        else
        {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }
}
