import { Component, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

import { UserModel } from '@models';
import { MenuItem } from 'primeng/api';
import { LayoutComponent } from '@theme/components';

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss'],
    animations: [
        trigger('overlayMenuAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scaleY(0.8)' }),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
            ]),
            transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class TopBarComponent implements OnInit, OnDestroy
{
    subscription: Subscription;
    items: MenuItem[];
    user: UserModel;

    private destroy$: Subject<void> = new Subject<void>();
    private redirectDelay = 0;

    constructor(
        public layoutComponent: LayoutComponent,
        private authService: NbAuthService,
        private router: Router,
        // private breadcrumbService: BreadcrumbService,
    )
    {
    }

    ngOnInit()
    {
        // this.subscription = breadcrumbService.itemsHandler.subscribe(response =>
        // {
        //     this.items = response;
        // });
        this.authService.onTokenChange()
            .pipe(
                takeUntil(this.destroy$),
            )
            .subscribe((token: NbAuthJWTToken) =>
            {
                if (token.isValid())
                {
                    this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
                }
            });
    }

    ngOnDestroy()
    {
        this.destroy$.next();
        this.destroy$.complete();

        if (this.subscription)
        {
            this.subscription.unsubscribe();
        }
    }

    logout()
    {
        this.authService.logout('email')
            .subscribe((result) =>
            {
                const redirect = result.getRedirect();

                if (redirect)
                {
                    setTimeout(() =>
                    {
                        return this.router.navigateByUrl(redirect);
                    }, this.redirectDelay);
                }
            });
    }
}
