import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SurveyTypeModel } from '@models';

@Injectable({
    providedIn: 'root'
})
export class SurveyTypeService
{
    private url = '/surveyTypes';

    constructor(
        private http: HttpClient,
    )
    {
    }

    getSurveyTypes(): Promise<SurveyTypeModel[]>
    {
        return this.http
            .get<SurveyTypeModel[]>(this.url)
            .toPromise();
    }
}
