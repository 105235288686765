import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentModel } from '../models/document/document.model';
import { UpdateDocumentModel } from '../models/document/update-document.model';
import { CreateDocumentModel } from '../models/document/create-document.model';

@Injectable({
    providedIn: 'root'
})
export class DocumentService
{
    private url = '/documents';

    constructor(
        private http: HttpClient,
    )
    {
    }

    get(): Promise<DocumentModel[]>
    {
        return this.http
            .get<DocumentModel[]>(this.url)
            .toPromise();
    }

    delete(documentId: number): Promise<void>
    {
        const url = `${this.url}/${documentId}`;

        return this.http
            .delete<void>(url)
            .toPromise();
    }

    update(document: UpdateDocumentModel): Promise<void>
    {
        const form_data: FormData = new FormData();
        form_data.append('id', document.id.toString());
        form_data.append('file', document.file);
        form_data.append('name', document.name);
        form_data.append('global', document.global ? '1' : '0');
        form_data.append('store_ids', JSON.stringify(document.store_ids));
        form_data.append('document_dir_id', document.document_dir_id.toString());

        return this.http
            .post<void>(`${this.url}/update`, form_data)
            .toPromise();
    }

    create(document: CreateDocumentModel): Promise<void>
    {
        const form_data: FormData = new FormData();
        form_data.append('file', document.file);
        form_data.append('name', document.name);
        form_data.append('global', document.global ? '1' : '0');
        form_data.append('store_ids', JSON.stringify(document.store_ids));

        if (document.document_dir_id)
        {
            form_data.append('document_dir_id', document.document_dir_id.toString());
        }

        return this.http
            .post<void>(this.url, form_data)
            .toPromise();
    }
}
