import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

export class EndpointInterceptor implements HttpInterceptor
{
    private static buildUrl(reqUrl: string): string
    {
        const domain = environment.domain;
        const url = reqUrl.indexOf(domain) > -1 ?
            reqUrl :
            domain + reqUrl;

        return url;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        const url = EndpointInterceptor.buildUrl(req.url);
        let params = req.params;

        if (environment.debug)
        {
            params = params.append('XDEBUG_SESSION_START', 'PHPSTORM');
        }

        const endpointReq = req.clone({ url, params });

        return next.handle(endpointReq);
    }
}
