import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MileageModel } from '@models';

@Injectable({
    providedIn: 'root'
})
export class MileageService
{
    private url = '/mileages';

    constructor(
        private http: HttpClient,
    )
    {
    }

    getAll(): Promise<MileageModel[]>
    {
        return this.http
            .get<MileageModel[]>(this.url)
            .toPromise();
    }
}
