import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';

@Component({
    selector: 'app-left-menu',
    templateUrl: './left-menu.component.html',
    styleUrls: ['./left-menu.component.scss'],
    animations: [
        trigger('submenu', [
            state('hidden', style({
                height: '0',
                overflow: 'hidden',
                opacity: 0,
            })),
            state('visible', style({
                height: '*',
                opacity: 1
            })),
            transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
        ])
    ]
})
export class LeftMenuComponent
{
    @Input() active: boolean;
    activeSubmenus: { [key: string]: boolean } = {};

    constructor(
        private router: Router,
    )
    {
    }

    toggleSubmenu(event: Event, name: string)
    {
        event.preventDefault();
        this.activeSubmenus[name] = this.activeSubmenus[name] ? false : true;
    }

    isSubmenuActive(name: string)
    {
        if (this.activeSubmenus.hasOwnProperty(name))
        {
            return this.activeSubmenus[name];
        }
        else if (this.router.isActive(name, false))
        {
            this.activeSubmenus[name] = true;

            return true;
        }

        return false;
    }
}
