import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CompanyModel } from '../models/company/company.model';
import { UpdateCompanyModel } from '../models/company/update-company.model';

@Injectable({
    providedIn: 'root'
})
export class CompanyService
{

    private url = '/companies';

    constructor(
        private http: HttpClient,
    )
    {
    }

    get(): Promise<CompanyModel[]>
    {
        return this.http
            .get<CompanyModel[]>(this.url)
            .toPromise();
    }

    getUserCompany(): Promise<CompanyModel>
    {
        return this.http
            .get<CompanyModel>(`${this.url}/getUserCompany`)
            .toPromise();
    }

    updateSetting(company: UpdateCompanyModel): Promise<void>
    {
        return this.http
            .put<void>(`${this.url}/updateSetting`, company)
            .toPromise();
    }
}
