import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { CreateCallCycleModel, UpdateRepStoreModel } from '@models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CallService
{
    private url = '/calls';

    constructor(
        private http: HttpClient,
    )
    {
    }

    create(cycle: CreateCallCycleModel): Promise<void>
    {
        return this.http
            .post<void>(this.url, cycle)
            .toPromise();
    }

    update(cycle: UpdateRepStoreModel): Promise<void>
    {
        return this.http
            .put<void>(this.url, cycle)
            .toPromise();
    }

    getCallCycle(start: number, rows: number, sortField: string, sortOrder: number, keyword: string, startDate: string, endDate: string): Observable<any>
    {
        return this.http
            .get<any>(`${this.url}?first=${start}&rows=${rows}&sortField=${sortField}&sortOrder=${sortOrder}&keyword=${keyword}&startDate=${startDate}&endDate=${endDate}`);
    }

    delete(cycleId: number): Promise<void>
    {
        const url = `${this.url}/${cycleId}`;

        return this.http
            .delete<void>(url)
            .toPromise();
    }

    exportExcel(sortField: string, sortOrder: number, keyword: string, startDate: string, endDate: string)
    {
        const url = `${this.url}/exportExcel`;
        const params = new HttpParams()
            .set('sortField', sortField)
            .set('sortOrder', sortOrder.toString())
            .set('keyword', keyword)
            .set('startDate', startDate)
            .set('endDate', endDate);

        return this.http
            .get<any>(url, { params, observe: 'response', responseType: 'blob' as 'json', })
            .pipe(
                map((response) =>
                {
                    const blob = response.body as Blob;
                    saveAs(blob);

                    return blob;
                }),
            )
            .toPromise();
    }
}
