import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CopySurveyModel, CreateSurveyModel, SurveyModel, UpdateSurveyModel } from '@models';
import { UpdateSurveyStoreModel } from '../models/survey/update-survey-store.model';

@Injectable({
    providedIn: 'root'
})
export class SurveyService
{
    private url = '/surveys';

    constructor(
        private http: HttpClient,
    )
    {
    }

    getSurveys(): Promise<SurveyModel[]>
    {
        return this.http
            .get<SurveyModel[]>(this.url)
            .toPromise();
    }

    delete(surveyId: number): Promise<void>
    {
        const url = `${this.url}/${surveyId}`;

        return this.http
            .delete<void>(url)
            .toPromise();
    }

    update(survey: UpdateSurveyModel): Promise<void>
    {
        return this.http
            .put<void>(this.url, survey)
            .toPromise();
    }

    create(survey: CreateSurveyModel): Promise<SurveyModel>
    {
        return this.http
            .post<SurveyModel>(this.url, survey)
            .toPromise();
    }

    getSurvey(surveyId: number): Promise<SurveyModel>
    {
        const url = `${'/survey'}/${surveyId}`;
        return this.http
            .get<SurveyModel>(url)
            .toPromise();
    }

    copy(survey: CopySurveyModel): Promise<SurveyModel>
    {
        const url = `${this.url}/copy`;

        return this.http
            .post<SurveyModel>(url, survey)
            .toPromise();
    }

    updateSurveyStore(surveyStore: UpdateSurveyStoreModel): Promise<void>
    {
        return this.http
            .put<void>(`${this.url}/surveyStore`, surveyStore)
            .toPromise();
    }
}
