import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CreateStoreModel, StoreModel, UpdateStoreModel } from '@models';
import { Observable } from 'rxjs';
import { AssignChannelsModel } from '../models/store/assign-channels.model';
import { ProductModel } from '../models/product/product.model';

@Injectable({
    providedIn: 'root'
})
export class StoreService
{
    private url = '/stores';

    constructor(
        private http: HttpClient,
    )
    {
    }

    create(store: CreateStoreModel): Promise<StoreModel>
    {
        return this.http
            .post<StoreModel>(this.url, store)
            .toPromise();
    }

    update(store: UpdateStoreModel): Promise<void>
    {
        return this.http
            .put<void>(this.url, store)
            .toPromise();
    }

    getStore(id: number): Promise<StoreModel>
    {
        const url = `${this.url}/${id}`;

        return this.http
            .get<StoreModel>(url)
            .toPromise();
    }

    getStoreProducts(id: number): Promise<ProductModel[]>
    {
        const url = `${this.url}/${id}/products`;

        return this.http
            .get<ProductModel[]>(url)
            .toPromise();
    }

    getStores(): Promise<StoreModel[]>
    {
        return this.http
            .get<StoreModel[]>(this.url)
            .toPromise();
    }

    delete(storeId: number): Promise<void>
    {
        const url = `${this.url}/${storeId}`;

        return this.http
            .delete<void>(url)
            .toPromise();
    }

    getStoresForFilter(keyword): Observable<StoreModel[]>
    {
        return this.http
            .get<StoreModel[]>(`${this.url}/getStoresForFilter?keyword=${keyword}`);
    }

    assignChannels(assignChannels: AssignChannelsModel): Promise<void>
    {
        return this.http
            .post<void>(`${this.url}/assignChannels`, assignChannels)
            .toPromise();
    }

    getNotVisitedStores()
    {
        return this.http
            .get<StoreModel[]>(`${this.url}/getNotVisitedStores`)
            .toPromise();
    }
}
