import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
})
export class ToastComponent implements OnInit
{

    constructor()
    {
    }

    ngOnInit(): void
    {
    }

}
