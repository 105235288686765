<div class="layout-topbar">
    <div class="topbar-left">
        <a tabindex="0" class="menu-button" (click)="layoutComponent.onMenuButtonClick($event)">
            <i class="pi pi-chevron-left"></i>
        </a>
        <span class="topbar-separator"></span>

        <!--        <ng-template ngFor let-item let-last="last" [ngForOf]="items">-->
        <!--            <span class="viewname" style="text-transform: uppercase">{{item.label}}</span>-->
        <!--        </ng-template>-->

        <!--        <img id="logo-mobile" class="mobile-logo" src="assets/layout/images/logo-dark.svg" alt="diamond-layout"/>-->

    </div>

    <div class="topbar-right">

        <ul class="topbar-menu">
            <li *ngIf="user"
                class="profile-item"
                [ngClass]="{'active-menuitem fadeInDown': layoutComponent.topBarUserMenuActive}">

                <a href="#" (click)="layoutComponent.onTopBarUserMenuButtonClick($event)">
                    <img *ngIf="user.picture" [src]="user.picture" alt="diamond-layout" class="profile-image"/>
                    <span class="profile-name">{{ user.name }}</span>
                </a>

                <ul class="profile-menu fade-in-up">
                    <li>
                        <a (click)="logout()">
                            <i class="pi pi-power-off"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>

            </li>
        </ul>

    </div>
</div>
