import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OrderModel } from '../models/order/order.model';
import { AddProductModel } from '../models/product/add-product.model';
import { ProductModel } from '../models/product/product.model';
import { UpdateOrderModel } from '../models/order/update-order.model';
import { CreateOrderModel } from '@models';

@Injectable({
    providedIn: 'root'
})
export class OrderService
{
    private url = '/orders';

    constructor(
        private http: HttpClient,
    )
    {
    }

    getOrders(dateRange: Date[])
    {
        const params = new HttpParams()
            .set('start_date', dateRange[0].toISOString())
            .set('end_date', dateRange[1].toISOString());

        return this.http
            .get<OrderModel[]>(this.url, { params, })
            .toPromise();
    }

    getOrder(orderId: number)
    {
        return this.http
            .get<OrderModel>(`${this.url}/${orderId}`)
            .toPromise();
    }

    delete(orderId: number): Promise<void>
    {
        const url = `${this.url}/${orderId}`;

        return this.http
            .delete<void>(url)
            .toPromise();
    }

    create(newOrder: CreateOrderModel): Promise<OrderModel>
    {
        return this.http
            .post<OrderModel>(this.url, newOrder)
            .toPromise();
    }

    update(order: UpdateOrderModel): Promise<void>
    {
        return this.http
            .put<void>(this.url, order)
            .toPromise();
    }

    addProduct(addedProduct: AddProductModel): Promise<ProductModel>
    {
        const url = `${this.url}/addProduct`;

        return this.http
            .post<ProductModel>(url, addedProduct)
            .toPromise();
    }

    updateProduct(products: ProductModel[]): Promise<number>
    {
        const url = `${this.url}/updateProduct`;

        return this.http
            .put<number>(url, products)
            .toPromise();
    }

    changeOrderStatus(orderId: number)
    {
        const url = `${this.url}/${orderId}/changeOrderStatus`;

        return this.http
            .get<boolean>(url)
            .toPromise();
    }
}

