<div class="layout-footer">
    <div class="layout-footer-left">
        <span class="created-by">Created with ♥ by</span>
        <b>
            <a href="https://weareconnect.co.za/" target="_blank">
                The Connect Group
            </a>
        </b>
    </div>

    <div class="layout-footer-right">
        <a href="mailto:info@weareconnect.co.za" class="p-mr-3">
            <i class="pi pi-question-circle"></i> Help
        </a>
    </div>
</div>
