<div class="layout-wrapper layout-static layout-sidebar-darkgray" data-theme="light"
     [ngClass]="{
     'layout-mobile-active': staticMenuMobileActive,
     'layout-static-inactive': staticMenuDesktopInactive}"
     (click)="onLayoutClick()">

    <div class="layout-content-wrapper">
        <app-top-bar></app-top-bar>

        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>

        <app-footer></app-footer>
    </div>

    <app-left-menu></app-left-menu>

    <div class="layout-mask modal-in"></div>
</div>
<app-toast></app-toast>
